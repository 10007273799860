import is from '@sindresorhus/is';
import { hasProperty } from '@workos-inc/standard/object';
import axios, { AxiosResponse } from 'axios';
import Cookies from 'js-cookie';
import jwt from 'jsonwebtoken';
import { ADMIN_PORTAL_API_URL_COOKIE } from './get-cookies';

export enum Intent {
  DSync = 'dsync',
  SSO = 'sso',
}

const INTENT_COOKIE = '_admin_portal_intent';

export const validateToken = async (token: string): Promise<AxiosResponse> => {
  isTokenExpired(token);

  const decoded = jwt.decode(token);

  const customBrandedApiUrl =
    decoded &&
    typeof decoded === 'object' &&
    hasProperty('api_url')(decoded) &&
    typeof decoded.api_url === 'string'
      ? decoded.api_url
      : undefined;

  if (customBrandedApiUrl) {
    Cookies.set(ADMIN_PORTAL_API_URL_COOKIE, customBrandedApiUrl);
  }

  const apiUrl = customBrandedApiUrl || process.env.NEXT_PUBLIC_API_URL;

  try {
    const response = await axios.post(
      `${apiUrl}/portal/init_session`,
      {
        token,
      },
      { withCredentials: true },
    );

    const { csrf } = response.data;
    const intent = getTokenIntent(token);

    Cookies.set('_csrf', csrf);
    Cookies.set(INTENT_COOKIE, intent);

    return response;
  } catch (error) {
    if (is.error(error)) {
      throw error;
    }

    throw new Error(String(error));
  }
};

const isTokenExpired = (token: string): boolean => {
  const decoded = jwt.decode(token);

  if (
    typeof decoded === 'object' &&
    decoded !== null &&
    typeof decoded.exp !== 'undefined' &&
    Date.now() >= decoded.exp * 1000
  ) {
    throw new Error('Token expired');
  }

  return true;
};

const getTokenIntent = (token: string) => {
  const decoded = jwt.decode(token);

  if (typeof decoded === 'object' && decoded?.intent) {
    return decoded.intent;
  }
};

export const getIntentFromCookie = (): string | undefined =>
  Cookies.get(INTENT_COOKIE);
